/* eslint-disable no-case-declarations */
import React, { FC, ReactElement } from 'react';
// Interfaces
import { DisplayEnum, SliceRaceListView, SliceTypeEnum, SliceView } from '@utmb/types/index';
import {
    DynamicAccordion,
    DynamicAnchorsNavigation,
    DynamicBackground,
    DynamicBannerDiscover,
    DynamicBannerHero,
    DynamicBannerMini,
    DynamicBusReservation,
    DynamicCardCollection,
    DynamicChallengeSlider,
    DynamicChronology,
    DynamicCityMaps,
    DynamicContactForm,
    DynamicCreateYourAccount,
    DynamicEventsWorldMap,
    DynamicJoinTheAdventure,
    DynamicKeyFigures,
    DynamicListOfMag,
    DynamicMixed,
    DynamicPartners,
    DynamicPhotoLayout,
    DynamicPictureOnly,
    DynamicPreregistration,
    DynamicPromotionSlider,
    DynamicQualifierSearch,
    DynamicQuestPromo,
    DynamicQuote,
    DynamicRacesList,
    DynamicRaceSwipeCards,
    DynamicRaceTrack,
    DynamicResults,
    DynamicRegistrationBanner,
    DynamicSection,
    DynamicSectionHeader,
    DynamicStartElites,
    DynamicStoneAllocations,
    DynamicTransportMap,
    DynamicUpcomingEvents,
    DynamicVideo,
    DynamicWorldIndexTop,
    DynamicWSIndexRankingTable,
    DynamicWorldSeriesRaceList,
    DynamicExpandableCardsCollection,
    DynamicLatestNews,
    DynamicStartList,
} from './dynamic-slice';
import { FaqMicrodata } from './FaqMicrodata';

//const PlaceHolder = (data: any): ReactElement => <>{console.log(`SlicePlaceHolder ${JSON.stringify(data)}`)}</>;
const PlaceHolder = (): ReactElement => <>{}</>;

const ListOfRacesWrapper: FC<SliceRaceListView> = (props) => {
    if (props.display === DisplayEnum.CARDS) {
        return <DynamicRacesList {...props} />;
    } /* if (props.display === DisplayEnum.SWIPPABLE_CARDS)*/ else {
        return <DynamicRaceSwipeCards {...props} />;
    } // else return <DynamicComparePromo {...props} />;
};

const Components: any = {
    [SliceTypeEnum.ACCESS_MAP]: DynamicTransportMap,
    [SliceTypeEnum.ACCORDION]: DynamicAccordion,
    [SliceTypeEnum.BACKGROUND]: DynamicBackground,
    [SliceTypeEnum.BANNER_DISCOVER]: DynamicBannerDiscover,
    [SliceTypeEnum.BANNER_HERO]: DynamicBannerHero,
    [SliceTypeEnum.BANNER_MINI]: DynamicBannerMini,
    [SliceTypeEnum.BUS_RESERVATION]: DynamicBusReservation,
    [SliceTypeEnum.CARDS_COLLECTION]: DynamicCardCollection,
    [SliceTypeEnum.CHRONOLOGY]: DynamicChronology,
    [SliceTypeEnum.CITY_MAP]: DynamicCityMaps,
    [SliceTypeEnum.CONTACT_FORM]: DynamicContactForm,
    [SliceTypeEnum.CREATE_YOUR_ACCOUNT]: DynamicCreateYourAccount,
    [SliceTypeEnum.EVENTS_EVENTS_UPCOMING]: DynamicUpcomingEvents,
    [SliceTypeEnum.EVENTS_QUALIFIER_SEARCH]: DynamicQualifierSearch,
    [SliceTypeEnum.EVENTS_WORLD_INDEX_RANKING_TABLE]: DynamicWSIndexRankingTable,
    [SliceTypeEnum.EVENTS_WORLD_INDEX_TOP]: DynamicWorldIndexTop,
    [SliceTypeEnum.EVENTS_WORLD_MAP]: DynamicEventsWorldMap,
    [SliceTypeEnum.EVENTS_WORLD_SERIES_RACE_LIST]: DynamicWorldSeriesRaceList,
    [SliceTypeEnum.EXPANDABLE_CARDS_COLLECTION]: DynamicExpandableCardsCollection,
    [SliceTypeEnum.INNER_NAV]: DynamicAnchorsNavigation,
    [SliceTypeEnum.LATEST_NEWS]: DynamicLatestNews,
    [SliceTypeEnum.LIST_OF_MAG]: DynamicListOfMag,
    [SliceTypeEnum.LIST_OF_RACES]: ListOfRacesWrapper,
    [SliceTypeEnum.MIXED]: DynamicMixed,
    [SliceTypeEnum.NEWSLETTER_FORM]: PlaceHolder,
    [SliceTypeEnum.PARTNERS]: DynamicPartners,
    [SliceTypeEnum.PHOTO_GALLERY]: DynamicPhotoLayout,
    [SliceTypeEnum.PICTURE_ONLY]: DynamicPictureOnly,
    [SliceTypeEnum.PREREGISTRATION]: DynamicPreregistration,
    [SliceTypeEnum.PROMOTION_SLIDER]: DynamicPromotionSlider,
    [SliceTypeEnum.QUEST_LIST]: DynamicJoinTheAdventure,
    [SliceTypeEnum.QUEST_SELECTOR]: DynamicQuestPromo,
    [SliceTypeEnum.QUOTE_SLIDER]: DynamicChallengeSlider,
    [SliceTypeEnum.QUOTE]: DynamicQuote,
    [SliceTypeEnum.RACE_BANNER]: DynamicRegistrationBanner,
    [SliceTypeEnum.RACE_PROFILE_MAP_TABLE]: DynamicRaceTrack,
    [SliceTypeEnum.RACE_STATS]: DynamicKeyFigures,
    [SliceTypeEnum.RESULTS]: DynamicResults,
    [SliceTypeEnum.START_ELITES]: DynamicStartElites,
    [SliceTypeEnum.START_LIST]: DynamicStartList,
    [SliceTypeEnum.SECTION]: DynamicSection,
    [SliceTypeEnum.STONES_ALLOCATIONS]: DynamicStoneAllocations,
    [SliceTypeEnum.TITLE_SUBTITLE]: DynamicSectionHeader,
    [SliceTypeEnum.VIDEO_ONLY]: DynamicVideo,
};

// Contains components that render slices
const componentsRecursive = [SliceTypeEnum.BACKGROUND];

function getSlices(slices: SliceView[]): any[] {
    return slices.map((slice: SliceView, index) => (
        <React.Fragment key={`sliceContainer${index}`}>
            {/* eslint-disable-next-line jsx-a11y/anchor-has-content */}
            <a id={`slice${index + 1}`}></a>
            {DynamicComponent(slice)}
        </React.Fragment>
    ));
}

function DynamicComponent({ type, uid, data, isDark }: SliceView): ReactElement {
    if (typeof Components[type] !== 'undefined') {
        const Component = Components[type];

        if (componentsRecursive.includes(type) && data && 'slices' in data) {
            return (
                <Component id={uid} key={uid} {...(data as any)} isDark={isDark}>
                    {getSlices(data.slices)}
                </Component>
            );
        }

        return <Component id={uid} key={uid} {...(data as any)} isDark={isDark} />;
    }
    return <></>;
}

interface SlicesHandlerProps {
    slices: SliceView[];
}

export const SlicesHandler = (props: SlicesHandlerProps): ReactElement => {
    const { slices = [] } = props;

    return (
        <>
            <FaqMicrodata slices={slices} />
            {getSlices(slices)}
        </>
    );
};
