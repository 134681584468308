import { marked } from 'marked';

/**
 * Tranform a markdown text into HTML
 * use marked.parse with addon for links
 *
 * @param {string} md
 *
 * @returns {string}
 */
export const parseMarkdown = (md: string): string => {
    const renderer = new marked.Renderer();
    renderer.link = function (href, title, text) {
        if (href?.startsWith('http')) {
            return `<a href="${href}" target="_blank"${title ? ` title="${title}"` : ''} ${
                !href?.includes('utmb.world') ? ' rel="nofollow noopener"' : ''
            }>${text}</a>`;
        } else {
            return `<a href="${href}"${title ? ` title="${title}"` : ''}>${text}</a>`;
        }
    };
    renderer.table = function (header, body): string {
        return `<div class="table-container"><table><thead>${header}</thead><tbody>${body}</tbody></table></div>`;
    };

    return marked.parse(md, { renderer });
};
