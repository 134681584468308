import dynamic from 'next/dynamic';

const DynamicAccordion = dynamic(() => import('../../design-system/accordion/Accordion'));
const DynamicAnchorsNavigation = dynamic(() => import('../../features/anchors-navigation/AnchorsNavigation').then((comp) => comp.AnchorsNavigation as any));
const DynamicBackground = dynamic(() => import('../../design-system/section-custom-bg/SectionCustomBg'));
const DynamicBannerDiscover = dynamic(() => import('../../features/banner-discover/BannerDiscover').then((comp) => comp.BannerDiscover as any));
const DynamicBannerHero = dynamic(() => import('../../features/hero-banner/HeroBanner').then((comp) => comp.HeroBanner as any));
const DynamicBannerMini = dynamic(() => import('../../features/banner-mini/BannerMini').then((comp) => comp.BannerMini as any));
const DynamicCardCollection = dynamic(() => import('../../features/race/cards-collection/CardsCollection').then((comp) => comp.CardsCollection as any));
const DynamicChallengeSlider = dynamic(() => import('../../features/challenge-slider/ChallengeSlider').then((comp) => comp.ChallengeSlider as any));
const DynamicChronology = dynamic(() => import('../../features/chronology/Chronology').then((comp) => comp.Chronology as any));
const DynamicCityMaps = dynamic(() => import('../../features/city-maps/CityMaps').then((comp) => comp.CityMaps as any));
const DynamicComparePromo = dynamic(() => import('../../features/races/compare-promo/ComparePromo').then((comp) => comp.ComparePromo as any));
const DynamicRaceSwipeCards = dynamic(() => import('../../features/races/swipe-cards/RaceSwipeCards').then((comp) => comp.RaceSwipeCards as any));
const DynamicContactForm = dynamic(() => import('../../features/contact-form/ContactForm').then((comp) => comp.ContactForm as any));
const DynamicCreateYourAccount = dynamic(() => import('../../features/create-your-account/CreateYourAccount').then((comp) => comp.CreateYourAccount as any));
const DynamicEventsWorldMap = dynamic(() => import('../../features/events-world-map/EventsWorldMap').then((comp) => comp.EventsWorldMap as any), {
    ssr: false,
});
const DynamicExpandableCardsCollection = dynamic(() =>
    import('../../features/expandable-cards-collection/ExpandableCardsCollection').then((comp) => comp.ExpandableCardsCollection as any)
);
const DynamicJoinTheAdventure = dynamic(() => import('../../features/race/join-the-adventure/JoinTheAdventure').then((comp) => comp.JoinTheAdventure as any));
const DynamicKeyFigures = dynamic(() => import('../../features/race/key-figures/KeyFigures').then((comp) => comp.KeyFigures as any));
const DynamicLatestNews = dynamic(() => import('../../features/utmb-news/MagazineLatest').then((comp) => comp.MagazineLatest as any));
const DynamicListOfMag = dynamic(() => import('../../features/utmb-news/MagazineContainer').then((comp) => comp.MagazineContainer as any));
const DynamicMixed = dynamic(() => import('../../features/race/mixed/Mixed').then((comp) => comp.Mixed as any));
const DynamicPartners = dynamic(() => import('../../features/partners/Partners').then((comp) => comp.Partners as any));
const DynamicPhotoLayout = dynamic(() => import('../../features/photos-layout/PhotoLayout').then((comp) => comp.PhotosLayout as any), {
    ssr: false,
});
const DynamicPictureOnly = dynamic(() => import('../../features/picture-only/PictureOnly').then((comp) => comp.PictureOnly as any));
const DynamicPreregistration = dynamic(() => import('../preregistration/Preregistration').then((comp) => comp.Preregistration as any));
const DynamicPromotionSlider = dynamic(() => import('../../features/promotions-slider/PromotionsSlider').then((comp) => comp.PromotionSlider as any));
const DynamicQualifierSearch = dynamic(() => import('../../features/qualifiers/QualifiersContainer').then((comp) => comp.QualifiersContainer as any));
const DynamicQuestPromo = dynamic(() => import('../../features/choose-your-quest/tabbed-promo').then((comp) => comp.QuestPromo as any));
const DynamicQuote = dynamic(() => import('../../features/quote/Quote').then((comp) => comp.Quote as any));
const DynamicRacesList = dynamic(() => import('../../features/races/races-list/RacesList').then((comp) => comp.RacesList as any));
const DynamicRaceTrack = dynamic(() => import('../../features/race-track/RaceTrack').then((comp) => comp.RaceTrack as any));
const DynamicBusReservation = dynamic(() => import('../bus-reservation/BusReservation').then((comp) => comp.BusReservation as any));
const DynamicResults = dynamic(() => import('../../features/results/Results').then((comp) => comp.Results as any));
const DynamicRegistrationBanner = dynamic(() =>
    import('../../features/race/registration-banner/RegistrationBanner').then((comp) => comp.RegistrationBanner as any)
);
const DynamicSection = dynamic(() => import('../slice-section/slice-section').then((comp) => comp.Section as any));
const DynamicSectionHeader = dynamic(() => import('../../features/section-header/SectionHeader').then((comp) => comp.SectionHeader as any));
const DynamicStartElites = dynamic(() => import('../../features/start-elites/StartElites').then((comp) => comp.StartElites as any));
const DynamicStartList = dynamic(() => import('../../features/start-list/StartList').then((comp) => comp.StartList as any));
const DynamicStoneAllocations = dynamic(() => import('../../features/stone-allocations/StoneAllocations').then((comp) => comp.StoneAllocations as any));
const DynamicTransportMap = dynamic(() => import('../../features/access/transport-map/TransportMap').then((comp) => comp.TransportMap as any));
const DynamicUpcomingEvents = dynamic(() => import('../../features/upcoming-events/UpcomingEvents').then((comp) => comp.UpcomingEvents as any));
const DynamicVideo = dynamic(() => import('../../features/video/Video').then((comp) => comp.Video as any));
const DynamicWSIndexRankingTable = dynamic(() =>
    import('../../features/world-series/index-ranking-table/IndexRankingTable').then((comp) => comp.IndexRankingTable as any)
);
const DynamicWorldIndexTop = dynamic(() => import('../../features/world-index-top/WorldIndexTop').then((comp) => comp.WorldIndexTop as any));
const DynamicWorldSeriesRaceList = dynamic(() =>
    import('../../features/world-series/race-list/WorldSeriesRaceListContainer').then((comp) => comp.WorldSeriesRaceListContainer as any)
);

export {
    DynamicAccordion,
    DynamicAnchorsNavigation,
    DynamicBackground,
    DynamicBannerDiscover,
    DynamicBannerHero,
    DynamicBannerMini,
    DynamicCardCollection,
    DynamicChallengeSlider,
    DynamicChronology,
    DynamicCityMaps,
    DynamicComparePromo,
    DynamicContactForm,
    DynamicCreateYourAccount,
    DynamicEventsWorldMap,
    DynamicExpandableCardsCollection,
    DynamicJoinTheAdventure,
    DynamicKeyFigures,
    DynamicLatestNews,
    DynamicListOfMag,
    DynamicMixed,
    DynamicPartners,
    DynamicPhotoLayout,
    DynamicPictureOnly,
    DynamicPreregistration,
    DynamicPromotionSlider,
    DynamicQualifierSearch,
    DynamicQuestPromo,
    DynamicQuote,
    DynamicRacesList,
    DynamicRaceSwipeCards,
    DynamicRaceTrack,
    DynamicResults,
    DynamicBusReservation,
    DynamicRegistrationBanner,
    DynamicSection,
    DynamicSectionHeader,
    DynamicStartElites,
    DynamicStartList,
    DynamicStoneAllocations,
    DynamicTransportMap,
    DynamicUpcomingEvents,
    DynamicVideo,
    DynamicWorldIndexTop,
    DynamicWorldSeriesRaceList,
    DynamicWSIndexRankingTable,
};
